import { useState, useEffect, useRef } from "react";

import context from "./context";

const TendersContextProvider = (props) => {
  const userToken = localStorage.getItem("token");

  const [selectedPlan, setSelectedPlan] = useState("");

  const [token, setToken] = useState(userToken || "");

  const [popularCompanies, setPopularCompanies] = useState([]);

  const [finishedRegistration, setFinishedRegistration] = useState(false);

  const [showSearchDarwer, setShowSearchDrawer] = useState(false);

  const [popularCategories, setPopularCategories] = useState([]);
  const [tendersPopularCategories, setTendersPopularCategories] = useState([]);

  const [searchIsClicked, setSearchIsClicked] = useState(false);

  const showDrawerHandler = () => {
    setShowSearchDrawer((prev) => {
      return !prev;
    });
  };

  // TENDER DETAILS  ////////////////////////////////////////////////////////////////////////////

  const [showTenderDetail, setShowTenderDetail] = useState(false);
  const [prevRoute, setPrevRoute] = useState("/tenders");

  const [scrollCoordinates, setScrollCoordinates] = useState(0);

  /////// FETCH TENDERS //////////////////////////////////////////////////////////////////////

  const [tenders, setTenders] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isFetchingTenders, setIsFetchingTenders] = useState(false);
  const [scrollCount, setScrollCount] = useState(1);
  const [fetchLimiter, setFetchLimiter] = useState(null);
  const [apiPath, setApiPath] = useState("");
  const [searchParams, setSearchParams] = useState({
    title: "",
    categoryId: "",
    regionId: "",
    languageId: "",
    startDate: "",
    endDate: "",
    status: "",
    page: "",
    companyId: "",
  });

  const shouldLoadTendersRef = useRef(true);

  const setShouldLoadTenders = (value) => {
    shouldLoadTendersRef.current = value;
  };

  useEffect(() => {
    setSelectedCategory("");
  }, [apiPath]);

  useEffect(() => {
    setScrollCount(1);
    setTenders([]);
  }, [selectedCategory, apiPath]);

  // fetching

  const fetchData = () => {
    setIsFetchingTenders(true);
    var headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: headers,
    };
    console.log(searchParams);
    fetch(
      `https://backend.ichereta.com/api/${apiPath}?title=${searchParams.title}&category_id=${searchParams.categoryId}&region_id=${searchParams.regionId}&language_id=${searchParams.languageId}&start_date=${searchParams.startDate}&end_date=${searchParams.endDate}&status_id=${searchParams.status}&page=${scrollCount}&company_id=${searchParams.companyId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);

        //case to be removed
        if (result.status === true) {
          setFetchLimiter(result.tenders.next_page_url);

          if (scrollCount === 1) {
            setTenders(result.tenders.data);
          } else {
            setTenders((prevTenders) => [
              ...prevTenders,
              ...result.tenders.data,
            ]);
          }
          // console.log(tenders);
        }
        setIsFetchingTenders(false);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (
      (apiPath !== "" && shouldLoadTendersRef.current) ||
      (tenders.length === 0 && apiPath !== "")
    ) {
      let timer;
      setIsFetchingTenders(true);

      const debounceFetchData = () => {
        clearTimeout(timer);
        timer = setTimeout(fetchData, 500);
      };

      debounceFetchData();

      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, scrollCount, apiPath]);

  // SEARCHING FILTER ///////////////////////////////////////////////////////////////////////////

  const [categoryLists, setCategoryLists] = useState([]);
  const [regions, setRegions] = useState([]);
  const [language, setLanguage] = useState([]);

  const [feedCategoryLists, setFeedCategoryLists] = useState([]);
  const [feedRegions, setFeedRegions] = useState([]);
  const [feedLanguage, setFeedLanguage] = useState([]);

  const [isFetchingLangAndReg, setIsFetchingLangAndReg] = useState(false);
  const [isFetchingFeedCategories, setIsFetchingFeedCategories] = useState(
    false
  );

  const [tendersCategoryLists, setTendersCategoryLists] = useState([]);
  const [tendersRegions, setTendersRegions] = useState([]);
  const [tendersLanguage, setTendersLanguage] = useState([]);

  const [searchingTitle, setSearchingTitle] = useState("");
  const [searchingRegionId, setSearchingRegionId] = useState("");
  const [searchingLanguageId, setSearchingLanguageId] = useState("");
  const [searchingCategoryId, setSearchingCategoryId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const SearchTenders = () => {
    setSearchParams({
      ...searchParams,
      title: searchingTitle,
      categoryId: searchingCategoryId,
      regionId: searchingRegionId,
      languageId: searchingLanguageId,
      status: selectedStatus,
    });
    setScrollCount(1);
    setTenders([]);
    setSearchIsClicked(true);
    showDrawerHandler();
  };

  // SAVE COUNT /////////////////////////////////////////////////////////////////////////////////

  const [savedCount, setSavedCount] = useState(0);
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    companyName: "",
  });

  const getUserData = () => {
    setSavedCount(0);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://backend.ichereta.com/api/client/user-info", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSavedCount(result.unseen_tender_count);
        setUserInfo({
          name: result.name,
          email: result.email,
          companyName: result.company_name,
        });
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (userToken) {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  // BOTTOM NAVIGATION //////////////////////////////////////////////////////////////////////////

  const [clickedBtn, setClickedBtn] = useState("");
  const currentPath = document.location.pathname;
  useEffect(() => {
    let btnId = "";
    if (currentPath === "/tenders") {
      btnId = "Btn1";
    } else if (currentPath === "/myfeed") {
      btnId = "Btn2";
    } else if (currentPath === "/saved") {
      btnId = "Btn3";
    }

    setClickedBtn(btnId);
  }, [currentPath]);

  // COMPANY DETAIL /////////////////////////////////////////////////////////////////////////////

  const [companyDetail, setCompanyDetail] = useState(null);

  // ////////////////////////////////////////////////////////////////////////////////////////////
  const [preferance, setPreferance] = useState(false);

  // console.log(preferance, "is my feed empty");

  const getCategories = (filter) => {
    if (filter === "myfeed") {
      setIsFetchingFeedCategories(true);
    }

    var headers = new Headers();
    headers.append("Authorization", `Bearer ${userToken}`);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: headers,
    };

    fetch(
      `https://backend.ichereta.com/api/tender/categories?path=${filter}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (filter === "") {
          setTendersCategoryLists(result);
        } else if (filter === "myfeed") {
          setFeedCategoryLists(result);
          setIsFetchingFeedCategories(false);
          result.length !== 0 ? setPreferance(false) : setPreferance(true);
        } else {
          setCategoryLists(result);
        }
      })
      .catch((error) => {
        setIsFetchingFeedCategories(false);
        console.log("error", error);
      });
  };

  const getLanguagesAndRegions = (filter) => {
    setIsFetchingLangAndReg(true);
    var headers = new Headers();
    headers.append("Authorization", `Bearer ${userToken}`);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: headers,
    };

    fetch(
      `https://backend.ichereta.com/api/tender/lang-reg?path=${filter}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (filter === "") {
          setTendersLanguage(result.languages);
          setTendersRegions(result.regions);
        } else if (filter === "myfeed") {
          setIsFetchingLangAndReg(false);
          setFeedLanguage(result.languages);
          setFeedRegions(result.regions);
          result.languages.length !== 0 || result.regions.length !== 0
            ? setPreferance(false)
            : setPreferance(true);
        } else {
          setLanguage(result.languages);
          setRegions(result.regions);
        }
      })
      .catch((error) => {
        setIsFetchingLangAndReg(false);
        console.log("error", error);
      });
  };

  // VALUES /////////////////////////////////////////////////////////////////////////////////////

  const value = {
    userInfo,
    selectedPrice: selectedPlan,
    setSelectedPlan,
    token: token,
    setToken,
    finishedRegistration,
    setFinishedRegistration,
    popularCompanies: popularCompanies,
    setPopularCompanies,
    showSearchDarwer,
    setShowSearchDrawer,
    showDrawerHandler,
    popularCategories,
    setPopularCategories,
    tendersPopularCategories,
    setTendersPopularCategories,

    // tenders fetching
    fetchData,
    tenders,
    selectedCategory,
    setSelectedCategory,
    isFetchingTenders,
    scrollCount,
    setScrollCount,
    fetchLimiter,

    // search filter
    categoryLists,
    setCategoryLists,
    regions,
    setRegions,
    language,
    setLanguage,
    SearchTenders,
    searchingTitle,
    setSearchingTitle,
    setSearchingRegionId,
    setSearchingLanguageId,
    setSearchingCategoryId,
    setSearchParams,

    tendersCategoryLists,
    setTendersCategoryLists,
    tendersRegions,
    setTendersRegions,
    tendersLanguage,
    setTendersLanguage,
    feedCategoryLists,
    isFetchingFeedCategories,
    feedRegions,
    feedLanguage,
    getCategories,
    getLanguagesAndRegions,
    isFetchingLangAndReg,
    preferance,
    setPreferance,

    //
    searchIsClicked,
    setSearchIsClicked,
    selectedStatus,
    setSelectedStatus,

    // saved Alert
    savedCount,
    setSavedCount,

    setTenders,
    // change page
    setApiPath,

    //bottom navigation
    clickedBtn,
    setClickedBtn,
    //tender detail
    showTenderDetail,
    setShowTenderDetail,
    prevRoute,
    setPrevRoute,
    scrollCoordinates,
    setScrollCoordinates,

    setShouldLoadTenders,

    //company details
    companyDetail,
    setCompanyDetail,
  };

  return <context.Provider value={value}>{props.children}</context.Provider>;
};

export default TendersContextProvider;
