import { useState, useContext } from "react";
import context from "../../store/context";

import PreferanceForm from "../section/PreferanceForm";

import noPreferance from "../../assets/programmer.svg";
import zigzagArrow from "../../assets/trend.png";
import AuthenticationButton from "../Buttons/AuthenticationButton";

const NoPreferance = () => {
  const {
    preferance,
    fetchData,
    getCategories,
    getLanguagesAndRegions,
  } = useContext(context);
  const [showUpdatePreferance, setShowUpdatePreferance] = useState(false);

  const handleGetStarted = () => {
    setShowUpdatePreferance(true);
  };

  const fetchNewData = () => {
    fetchData();
    getCategories("myfeed");
    getLanguagesAndRegions("myfeed");
  };
  return (
    <>
      {preferance ? (
        showUpdatePreferance ? (
          <PreferanceForm onResponse={fetchNewData} />
        ) : (
          <div className="w-[95%] max-w-[500px] p-5 sm:p-8 text-[#3d5984] bg-white drop-shadow mt-[20%] md:mt-[10%] rounded-md flex flex-col items-center text-center space-y-5">
            <h1 className="font-medium sm:font-bold text-base sm:text-lg md:text-xl">
              Welcome to <span className="text-[#664bc7]">My Feed</span> Page.
            </h1>
            <p className="text-sm">
              Personalize your tender viewing experience by configuring your
              settings and viewing only the tenders that are relevant and
              important to you.
            </p>
            <div className="w-24 h-24 md:w-32 md:h-32">
              <img src={noPreferance} alt="no saved tenders" />
            </div>
            <div className="flex flex-col items-center space-y-2">
              <h1 className="text-xs sm:text-sm">
                To Ensure You Receive The Most Relevant Tenders, Update Your
                Preference Now. It'll Only Take A Few Seconds!
              </h1>
              <img
                className="w-8 rotate-[130deg]"
                src={zigzagArrow}
                alt="zig zar arrow"
              />
              <AuthenticationButton
                onClick={handleGetStarted}
                name="Get Started"
              />
            </div>
          </div>
        )
      ) : (
        <div className="mt-[20%] md:mt-[10%] bg-white shadow p-8 rounded-md">
          <h1>No Result</h1>
        </div>
      )}
    </>
  );
};

export default NoPreferance;
