import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// context
import ContextProvider from "./store/ContextProvider";

// pages
import Rootlayout from "./pages/Rootlayout";
import Homepage from "./pages/Home";
import LoginPage from "./pages/Auth/Login";
import SignupPage from "./pages/Auth/Signup";
import TendersPage from "./pages/Tender/Tenders";
import TenderDetail from "./pages/Tender/TenderDetail";
import PricingPage from "./pages/Pricing";
import FAQPage from "./pages/FAQ";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import MyFeed from "./pages/Tender/MyFeed";
import SavedTenders from "./pages/Tender/Saved";
import MyAccount from "./pages/Tender/MyAccount";
import BillingPage from "./pages/Tender/Billing";
import SettingsPage from "./pages/Tender/Settings";
import ErrorPage from "./pages/Error";
import CompanyDetail from "./pages/CompanyDetail";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Rootlayout />,
      errorElement: <ErrorPage />,
      children: [
        { index: true, element: <Homepage /> },
        { path: "login", element: <LoginPage /> },
        { path: "signup", element: <SignupPage /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "tenders", element: <TendersPage /> },
        { path: "tenders/:tenderId", element: <TenderDetail /> },
        { path: "company/:companyId", element: <CompanyDetail /> },
        { path: "pricing", element: <PricingPage /> },
        { path: "FAQ", element: <FAQPage /> },
        { path: "myfeed", element: <MyFeed /> },
        { path: "saved", element: <SavedTenders /> },
        { path: "myaccount", element: <MyAccount /> },
        { path: "billing", element: <BillingPage /> },
        { path: "settings", element: <SettingsPage /> },
      ],
    },
  ]);

  const currentPath = window.location.pathname;
  const token = localStorage.getItem("token");
  if (token && currentPath === "/") {
    router.navigate("/tenders");
  }

  useEffect(() => {
    if (
      !token &&
      (currentPath === "/settings" ||
        currentPath === "/billing" ||
        currentPath === "/myaccount" ||
        currentPath === "/myfeed")
    ) {
      router.navigate("/");
    }
  }, [router, token, currentPath]);

  useEffect(() => {
    const handleRouteChange = () => {
      window.scrollTo(0, 0); // Scroll to top of the page
    };

    router.subscribe(handleRouteChange);

    // return () => {
    //   router.unsubscribe(handleRouteChange);
    // };
  }, [router]);

  return (
    <ContextProvider>
      <RouterProvider router={router} />
    </ContextProvider>
  );
}

export default App;
