import { useState, useEffect, useContext } from "react";

// context
import context from "../../store/context";

// components
import CategoryButton from "../Buttons/CategoryButton";
import CategoryButtonShimmer from "../Buttons/CategoryButtonShimmer";

// dummy
import dummyData from "../../DUMMY";

// icons

const CategoriesSlide = (props) => {
  const {
    popularCategories,
    setPopularCategories,
    tendersPopularCategories,
    setTendersPopularCategories,
    selectedCategory,
    setSelectedCategory,
    setSearchParams,
    searchIsClicked,
    setSearchIsClicked,
    selectedStatus,
    setSelectedStatus,
  } = useContext(context);

  const [isFetching, setIsFetching] = useState(false);

  const categoryButtonClickHandler = (event) => {
    if (selectedStatus !== "" || searchIsClicked) {
      setSearchIsClicked(false);
      setSelectedStatus("");
    }
    setSelectedCategory(event.currentTarget.id);
    setSearchParams({
      title: "",
      categoryId: event.currentTarget.id,
      regionId: "",
      languageId: "",
      startDate: "",
      endDate: "",
      status: "",
      page: "",
      companyId: "",
    });
  };

  const userToken = localStorage.getItem("token");

  useEffect(() => {
    if (
      (props.filter === "" && tendersPopularCategories.length === 0) ||
      props.filter === "myfeed"
    ) {
      setIsFetching(true);
      var headers = new Headers();
      headers.append("Authorization", `Bearer ${userToken}`);
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: headers,
      };

      fetch(
        `https://backend.ichereta.com/api/tender/popular/categories?path=${props.filter}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setIsFetching(false);
          setPopularCategories(result);
          props.onResponse(result);
          if (props.filter === "") {
            setTendersPopularCategories(result);
          }
        })
        .catch((error) => {
          setIsFetching(false);
          console.log("error", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter, userToken]);

  return (
    <div className="w-full flex items-center overflow-x-auto space-x-4 bg-white border-b border-[#664bc7]/10  px-2 sm:px-0 h-[50px] md:h-[60px] shadow-lg shadow-white">
      {!isFetching &&
        (popularCategories.length !== 0 ||
          (tendersPopularCategories.length !== 0 && props.filter === "")) && (
          <CategoryButton
            key="allTenders"
            id=""
            name="All Tenders"
            clickedId={selectedCategory}
            onClick={categoryButtonClickHandler}
          />
        )}
      {isFetching
        ? dummyData.map((item) => {
            return (
              <CategoryButtonShimmer key={item.id}></CategoryButtonShimmer>
            );
          })
        : props.filter === ""
        ? tendersPopularCategories.map((category) => {
            return (
              <CategoryButton
                key={category.id}
                clickedId={selectedCategory}
                onClick={categoryButtonClickHandler}
                id={category.id}
                name={category.name}
              ></CategoryButton>
            );
          })
        : popularCategories.map((category) => {
            return (
              <CategoryButton
                key={category.id}
                clickedId={selectedCategory}
                onClick={categoryButtonClickHandler}
                id={category.id}
                name={category.name}
              ></CategoryButton>
            );
          })}
    </div>
  );
};

export default CategoriesSlide;
