import { useState } from "react";
const useNewPassword = (reqParameters) => {
  const [isSending, setIsSending] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //fetch user's phone number
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var reqParams = {
    method: "POST",
    headers: myHeaders,
    body: reqParameters.data,
  };

  var parsed = JSON.parse(reqParams.body); // Parse the JSON string into an object

  const sendRequest = () => {
    if (reqParameters.case) {
      setIsSending(true);
      setHasError(false);

      // send request
      fetch(
        `https://backend.ichereta.com/api/auth/${reqParameters.route}`,
        reqParams
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            reqParameters.onClick && reqParameters.onClick();
            reqParameters.onSubmit && reqParameters.onSubmit(parsed.phone);
            reqParameters.loguserIn && reqParameters.loguserIn(data.token);
          } else {
            setErrorMessage(data.errors ? data.errors.phone : data.message);
            setHasError(true);
          }
          setIsSending(false);
        })
        .catch((err) => {
          setIsSending(false);
          setHasError(true);
          console.log(err);
        });
    }

    if (reqParameters.case2) {
      setHasError(true);
      setErrorMessage(reqParameters.errorMessage);
    }
  };

  return { isSending, hasError, errorMessage, sendRequest };
};

export default useNewPassword;
