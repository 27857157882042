import { useContext, useEffect } from "react";
import context from "../../store/context";

// import TendersLayout from "./TendersLayout";
import TendersList from "./TendersList";

const TendersPage = () => {
  const {
    setApiPath,
    setSearchParams,
    getCategories,
    tendersCategoryLists,
    getLanguagesAndRegions,
    tendersRegions,
    tendersLanguage,
  } = useContext(context);

  useEffect(() => {
    if (tendersCategoryLists.length === 0) {
      getCategories("");
    }
    if (tendersRegions.length === 0 || tendersLanguage.length === 0) {
      getLanguagesAndRegions("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setApiPath("tender/search");

    setSearchParams({
      title: "",
      categoryId: "",
      regionId: "",
      languageId: "",
      startDate: "",
      endDate: "",
      status: "",
      page: "",
      companyId: "",
    });
  }, [setApiPath, setSearchParams]);

  return (
    <TendersList
      filter={""}
      categorySlider={true}
      noTendersPlaceholder={<div>No result</div>}
    />
  );
};

export default TendersPage;
