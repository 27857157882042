import { useRef } from "react";

import SearchSidebar from "../../components/section/SearchSidebar";
// import PhoneVerificationAlert from "../../components/cards/PhoneVerificationAlert";
import CategoriesSlide from "../../components/UI/CategoriesSlide";

const TendersLayout = (props) => {
  const popularCatagories = useRef([0]);

  const setPopularCategories = (value) => {
    popularCatagories.current = value;
    console.log("value grabed");
  };

  return (
    <div
      className={`w-full sm:w-[90%]  max-w-[1500px] flex items-start sm:relative`}
    >
      <div className=" bg-white w-full sm:w-[350px] pr-5  hidden lg:flex flex-col sticky top-0 z-50 h-screen sm:h-auto sm:top-[73px] md:top-[69px] lg:top-[79px] left-0">
        <SearchSidebar filter={props.filter} redirect={props.redirect} />
      </div>

      {/* tenders section */}
      <div
        className={`flex flex-col relative items-center justify-start w-full lg:w-[400px] flex-grow bg-[#f8f8ff]/70`}
      >
        <div className="w-full sticky top-[61px] sm:top-[73px] md:top-[69px] lg:top-[79px] left-0 bg-white">
          <div className="px-1.5">{/* <PhoneVerificationAlert /> */}</div>
          {props.categorySlider && popularCatagories.current.length !== 0 && (
            <CategoriesSlide
              filter={props.filter}
              onResponse={setPopularCategories}
            />
          )}
        </div>

        <div
          className={`w-full flex flex-col items-center  ${
            props.categorySlider && popularCatagories.current.length !== 0
              ? " h-[calc(100vh-191px)] sm:h-[calc(100vh-203px)] md:h-[calc(100vh-129px)] lg:h-[calc(100vh-139px)]"
              : " h-[calc(100vh-141px)] sm:h-[calc(100vh-153px)] md:h-[calc(100vh-69px)] lg:h-[calc(100vh-79px)]"
          } `}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default TendersLayout;
