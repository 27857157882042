import { useContext } from "react";
import { useNavigate } from "react-router-dom";

//context
import context from "../../store/context";

import DateConverter from "../DateConverter";
import TimeDifference from "../TimeDifference";
import SaveUnsave from "../Buttons/SaveUnsave";

//icons
import CalendarIcon from "../icons/Calendar";

const TenderCard = ({
  title,
  openingDate,
  closingDate,
  createdAt,
  id,
  status,
  isSavedDB,
  currentPath,
}) => {
  const currentTime = new Date();

  const { setPrevRoute } = useContext(context);

  const navigate = useNavigate();

  const openDetailPage = () => {
    setPrevRoute(currentPath);
    navigate(`/tenders/${id}`);
  };

  const userToken = localStorage.getItem("token");
  const notAUser = "Login or Subscribe";
  const notAnActiveUser = "Subscribe";

  return (
    <div className="w-full flex flex-col bg-white rounded-md py-4 px-5 space-y-2 shadow text-[#3d5984]">
      <button
        onClick={openDetailPage}
        className="w-full flex flex-col items-start"
      >
        <div className="border-b border-b-gray-200/80 pb-2 text-sm sm:text-base font-semibold text-start">
          <h1>
            #{id} {currentPath} {title}
          </h1>
        </div>
        <div className="flex flex-col space-y-2 text-xs sm:text-sm items-start pt-2">
          <div className="flex items-center space-x-2">
            <h1 className="font-bold">Bid Closing Date:</h1>
            {closingDate ? (
              <DateConverter dateString={closingDate} />
            ) : userToken ? (
              <span>{notAnActiveUser}</span>
            ) : (
              <span>{notAUser}</span>
            )}
          </div>
          <div className="flex items-center space-x-2">
            <h1 className="font-bold">Bid Opening Date: </h1>
            {openingDate ? (
              <DateConverter dateString={openingDate} />
            ) : userToken ? (
              <span>{notAnActiveUser}</span>
            ) : (
              <span>{notAUser}</span>
            )}
          </div>

          <span>
            <span className="font-bold">Published On: </span> Reporter
          </span>
        </div>
      </button>
      <div className="flex flex-wrap items-center justify-between border-t border-t-gray-200/80 pt-2">
        <div className="space-x-2 md:space-x-6 text-xs sm:text-sm flex  items-center">
          <button
            className={`flex items-center font-semibold space-x-2 ${
              Number(status) === 1
                ? "text-green-600 bg-green-200"
                : "text-red-500 bg-red-200"
            }  bg-opacity-40 px-2 py-1 text-xs`}
          >
            <CalendarIcon styles="w-5 h-5" />
            {Number(status) === 1 ? (
              <span>BIDDING OPEN</span>
            ) : (
              <span>BIDDING CLOSED</span>
            )}
          </button>

          <div className="flex items-center space-x-2">
            <SaveUnsave
              isSavedDB={isSavedDB}
              id={id}
              currentPath={currentPath}
            />
          </div>
        </div>
        <span className="italic text-xs sm:text-sm font-thin">
          <TimeDifference currentTime={currentTime} dateString={createdAt} />
        </span>
      </div>
    </div>
  );
};

export default TenderCard;
