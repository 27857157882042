import SearchSidebar from "./SearchSidebar";

// icons
import CloseIcon from "../icons/Close";

const SearchSidebarPhone = (props) => {
  return (
    <div className="fixed top-0 md:top-[69px] left-0 w-full max-w-[400px] px-5 bg-white z-[101] min-h-screen lg:hidden border-r border-slate-100">
      <div className="flex items-center border-b border-slate-100 justify-between h-[61px] mb-5">
        <h1 className="text-lg font-bold text-[#664bc7]">Search Tenders</h1>
        <button
          onClick={props.onClick}
          className="bg-red-100 p-1 flex items-center justify-center rounded-full"
        >
          <CloseIcon styles="w-5 h-5 text-red-500" />
        </button>
      </div>
      <SearchSidebar />
    </div>
  );
};

export default SearchSidebarPhone;
