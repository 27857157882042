import React from "react";

function DateConverter({ dateString }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  };

  const formattedDate = formatDate(dateString);

  return <h1>{formattedDate}</h1>;
}

export default DateConverter;
