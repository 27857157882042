import { useEffect, useContext, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import context from "../../store/context";

import TenderCard from "../../components/cards/Tender";
import TenderShimmer from "../../components/cards/TenderShimmer";
import TendersLayout from "./TendersLayout";

import dummyData from "../../DUMMY";

const DEBOUNCE_DELAY = 200; // Adjust the delay as needed
const TendersList = (props) => {
  const {
    isFetchingTenders,
    selectedCategory,
    tenders,
    scrollCount,
    setScrollCount,
    fetchLimiter,
    scrollCoordinates,
    setScrollCoordinates,
    setShouldLoadTenders,
  } = useContext(context);

  const scrollContainerRef = useRef(null);
  const scrollPositionRef = useRef(0); // Ref to store scroll position

  const handleScroll = useCallback(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      const isBottom =
        scrollContainer.scrollTop + scrollContainer.clientHeight >=
        scrollContainer.scrollHeight - 300;

      if (isBottom && fetchLimiter && !isFetchingTenders) {
        setScrollCount((prevCount) => prevCount + 1);
      }

      scrollPositionRef.current = scrollContainer.scrollTop; // Update scroll position ref
      setScrollCoordinates(scrollPositionRef);
    }
  }, [setScrollCount, fetchLimiter, isFetchingTenders, setScrollCoordinates]);

  useEffect(() => {
    let timeoutId;

    const debouncedHandleScroll = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleScroll, DEBOUNCE_DELAY);
    };

    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", debouncedHandleScroll);
      scrollContainer.scrollTop = scrollPositionRef.current; // Restore scroll position
    }

    return () => {
      clearTimeout(timeoutId);
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", debouncedHandleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollPositionRef.current = 0; // Reset scroll position
    }
  }, [selectedCategory]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    scrollContainer.scrollTo(0, scrollCoordinates.current);
  }, [scrollCoordinates]);

  useEffect(() => {
    setTimeout(() => setShouldLoadTenders(true), 200);
  }, [setShouldLoadTenders]);

  const location = useLocation();

  const currentPath = location.pathname;

  return (
    <TendersLayout filter={props.filter} categorySlider={props.categorySlider}>
      <div
        className="w-full space-y-4 overflow-y-auto overflow-x-hidden flex flex-col items-center h-full px-2 py-3 sm:py-5 sm:px-5 pb-4"
        ref={scrollContainerRef}
      >
        {props.profileCard && props.profileCard}
        {isFetchingTenders && scrollCount === 1
          ? dummyData.map((item) => <TenderShimmer key={item.id} />)
          : tenders.length !== 0 &&
            tenders.map((tender, index) => (
              <TenderCard
                key={`${tender.id}-${index}`}
                id={tender.id}
                title={tender.title}
                openingDate={tender.bid_open_date}
                closingDate={tender.bid_close_date}
                createdAt={tender.created_at}
                status={tender.status}
                isSavedDB={tender.is_saved}
                route={props.route}
                currentPath={currentPath}
              />
            ))}

        {fetchLimiter ? (
          tenders.length !== 0 && <TenderShimmer />
        ) : scrollCount > 1 && tenders.length > 10 ? (
          <h1 className="sm:text-lg text-slate-300">No more content</h1>
        ) : (
          tenders.length === 0 && props.noTendersPlaceholder
        )}
      </div>
    </TendersLayout>
  );
};

export default TendersList;
