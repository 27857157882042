import { useState } from "react";

// components
import Input from "../Input";
import AuthenticationButton from "../Buttons/AuthenticationButton";

// hooks
// import useFetch from "../../hooks/use-fetch";
import useInput from "../../hooks/use-Input";

// icons
import PersonIcon from "../icons/Person";
import BuildingIcon from "../icons/Building";
import PhoneIcon from "../icons/Phone";

const RegistrationStepOne = (props) => {
  const [isSending, setIsSending] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const namesValidator = (enteredValue) => {
    return enteredValue.trim().length !== 0;
  };

  const phoneValidator = (enteredValue) => {
    const phoneRegex = /^(09|\+251)\d{8}$|^(\+251)\d{9}$/;
    return phoneRegex.test(enteredValue);
  };

  const {
    enteredValue: fNameValue,
    hasError: fNameHasError,
    valueChangeHandler: fNameValueChangeHandler,
    inputBlurHandler: fNameInputBlurHandler,
    isTouched: fNameIsTouched,
    isValid: fNameIsValid,
    reset: fNameReset,
  } = useInput(namesValidator, props.inputData?.fName || "");

  const {
    enteredValue: lNameValue,
    hasError: lNameHasError,
    valueChangeHandler: lNameValueChangeHandler,
    inputBlurHandler: lNameInputBlurHandler,
    isTouched: lNameIsTouched,
    isValid: lNameIsValid,
    reset: lNameReset,
  } = useInput(namesValidator, props.inputData?.lName || "");

  const {
    enteredValue: cNameValue,
    hasError: cNameHasError,
    valueChangeHandler: cNameValueChangeHandler,
    inputBlurHandler: cNameInputBlurHandler,
    isTouched: cNameIsTouched,
    isValid: cNameIsValid,
    reset: cNameReset,
  } = useInput(namesValidator, props.inputData?.cName || "");

  const {
    enteredValue: phoneValue,
    hasError: phoneHasError,
    valueChangeHandler: phoneValueChangeHandler,
    inputBlurHandler: phoneInputBlurHandler,
    isTouched: phoneIsTouched,
    isValid: phoneIsValid,
    reset: phoneReset,
  } = useInput(phoneValidator, props.inputData?.phone || "");

  //fetch step one

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const transformedPhoneValue = phoneValue.startsWith("+251")
    ? "0" + phoneValue.substring(4)
    : phoneValue;

  var raw = JSON.stringify({
    phone: transformedPhoneValue,
  });

  const toStepTwo = () => {
    if (fNameIsValid && lNameIsValid && cNameIsValid && phoneIsValid) {
      setIsSending(true);
      setHasError(false);
      // validate phone
      fetch(`https://backend.ichereta.com/api/auth/validate/phone`, {
        method: "POST",
        headers: myHeaders,
        body: raw,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            props.onClick();
          } else {
            setErrorMessage(data.errors.phone);
            setHasError(true);
          }
          setIsSending(false);
        })
        .catch((err) => {
          setIsSending(false);
          setHasError(true);
          console.log(err);
        });

      props.onSubmit({
        fName: fNameValue,
        lName: lNameValue,
        cName: cNameValue,
        phone: transformedPhoneValue,
      });
    }
  };

  return (
    <div className={` flex flex-col w-full space-y-3 pt-5 `}>
      <div className="w-full flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:space-x-5">
        <Input
          onChange={fNameValueChangeHandler}
          onBlur={fNameInputBlurHandler}
          hasError={fNameHasError}
          isValid={fNameIsValid}
          isTouched={fNameIsTouched}
          enteredValue={fNameValue}
          reset={fNameReset}
          type="text"
          id="firstName"
          lable="First Name"
        >
          <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
        <Input
          onChange={lNameValueChangeHandler}
          onBlur={lNameInputBlurHandler}
          hasError={lNameHasError}
          isValid={lNameIsValid}
          isTouched={lNameIsTouched}
          enteredValue={lNameValue}
          reset={lNameReset}
          type="text"
          id="LastName"
          lable="Last Name"
        >
          <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>

      <Input
        onChange={cNameValueChangeHandler}
        onBlur={cNameInputBlurHandler}
        hasError={cNameHasError}
        isValid={cNameIsValid}
        isTouched={cNameIsTouched}
        enteredValue={cNameValue}
        reset={cNameReset}
        type="text"
        id="companyName"
        lable="Company Name"
      >
        <BuildingIcon styles="w-6 h-6 mx-3 text-slate-400" />
      </Input>
      <Input
        onChange={phoneValueChangeHandler}
        onBlur={phoneInputBlurHandler}
        hasError={phoneHasError}
        isValid={phoneIsValid}
        isTouched={phoneIsTouched}
        enteredValue={phoneValue}
        reset={phoneReset}
        error={hasError}
        errorMessage={errorMessage}
        type="tel"
        id="mobileNumber"
        lable="Mobile Number"
      >
        <PhoneIcon styles="w-6 h-6 mx-3 text-slate-400" />
      </Input>

      <AuthenticationButton
        onClick={toStepTwo}
        process={isSending}
        name="Next"
      />
    </div>
  );
};

export default RegistrationStepOne;
