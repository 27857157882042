import { useState } from "react";
import Input from "../../components/Input";
import AuthenticationButton from "../../components/Buttons/AuthenticationButton";

//icons
import PersonIcon from "../../components/icons/Person";
import EmailIcon from "../../components/icons/Email";
import PhoneIcon from "../../components/icons/Phone";
import BuildingIcon from "../../components/icons/Building";
import LocationIcon from "../../components/icons/Location";
import TinIcon from "../../components/icons/Tin";
import LockIcon from "../../components/icons/Lock";

const MyAccount = () => {
  const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
  };

  const getButtonStyle = (buttonIndex) => {
    return (
      activeButton === buttonIndex &&
      "text-white transition-colors duration-200"
    );
  };

  return (
    <div className="w-full flex justify-center bg-[#f8f8ff] sm:bg-white">
      <div className="w-[95%] sm:w-[90%] max-w-[1500px] sm:bg-[#f8f8ff]  overflow-y-auto h-[calc(100vh-141px)] sm:h-[calc(100vh-153px)] md:h-[calc(100vh-69px)] lg:h-[calc(100vh-79px)] py-5 md:py-10">
        <div className="w-full flex justify-center text-[#3d5984]">
          <div className="w-[600px] flex flex-col space-y-5 md:space-y-10 items-center">
            <div className="w-full  rounded-md bg-white text-center px-5 py-5 md:py-8 space-y-2 md:space-y-5">
              <h1 className="text-lg md:text-xl font-bold">MY ACCOUNT</h1>
              <p className="text-xs sm:text-sm md:text-base">
                On this page you can add information <br /> about you and your
                company.
              </p>
            </div>
            <div className="w-[85%] bg-white p-1 rounded-full border border-[#3d5984]/50">
              <div className="flex items-center relative w-full rounded-full overflow-hidden z-10 text-xs sm:text-sm">
                <button
                  className={`px-4 py-2  ${getButtonStyle(
                    1
                  )} w-1/3 hover:bg-[#3d5984]/5 rounded-full transition-colors duration-200`}
                  onClick={() => handleButtonClick(1)}
                >
                  Personal
                </button>
                <button
                  className={`px-4 py-2  ${getButtonStyle(
                    2
                  )} w-1/3 hover:bg-[#3d5984]/5 rounded-full transition-colors duration-200`}
                  onClick={() => handleButtonClick(2)}
                >
                  Company
                </button>
                <button
                  className={`px-4 py-2 ${getButtonStyle(
                    3
                  )} w-1/3 hover:bg-[#3d5984]/5 rounded-full transition-colors duration-200`}
                  onClick={() => handleButtonClick(3)}
                >
                  Security
                </button>

                {/* Sliding background effect using Tailwind CSS classes */}
                <div
                  className="absolute bottom-0 left-0 h-2 bg-[#3d5984] transition-transform ease-in-out duration-200 h-full w-1/3 rounded-full -z-10"
                  style={{
                    transform: `translateX(${(activeButton - 1) * 100}%)`,
                  }}
                />
              </div>
            </div>
            <div className="w-full bg-white  rounded-md p-5">
              {activeButton === 1 && (
                <div className="flex flex-col">
                  <div className="border-b pb-2 mb-4">
                    <h1 className="text-lg font-medium">
                      Personal Information
                    </h1>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-sm">Full Name</h1>
                    <Input type="text" id="personalInfoName">
                      <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
                    </Input>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-sm">Email Address</h1>
                    <Input type="email" id="personalInfoEmail">
                      <EmailIcon styles="w-6 h-6 mx-3 text-slate-400" />
                    </Input>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-sm">Phone Number</h1>
                    <Input type="tel" id="personalInfoPhone">
                      <PhoneIcon styles="w-6 h-6 mx-3 text-slate-400" />
                    </Input>
                  </div>

                  <AuthenticationButton name="Save Changes" />
                </div>
              )}
              {activeButton === 2 && (
                <div className="flex flex-col">
                  <div className="border-b pb-2 mb-4">
                    <h1 className="text-lg font-medium">Company Information</h1>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-sm">Company Name</h1>
                    <Input type="text" id="companyInfoName">
                      <BuildingIcon styles="w-6 h-6 mx-3 text-slate-400" />
                    </Input>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-sm">TIN</h1>
                    <Input type="number" id="companyInfoTIN">
                      <TinIcon styles="w-6 h-6 mx-3 text-slate-400" />
                    </Input>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-sm">Address</h1>
                    <Input type="text" id="companyInfoAddress">
                      <LocationIcon styles="w-6 h-6 mx-3 text-slate-400" />
                    </Input>
                  </div>

                  <AuthenticationButton name="Save Changes" />
                </div>
              )}
              {activeButton === 3 && (
                <div className="flex flex-col">
                  <div className="border-b pb-2 mb-4">
                    <h1 className="text-lg font-medium">Change Password</h1>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-sm">Old Password</h1>
                    <Input type="password" id="changePasswordOldValue">
                      <LockIcon styles="w-6 h-6 mx-3 text-slate-400" />
                    </Input>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-sm">New Password</h1>
                    <Input type="password" id="changePasswordNewValue">
                      <LockIcon styles="w-6 h-6 mx-3 text-slate-400" />
                    </Input>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-sm">Confirm New Password</h1>
                    <Input type="password" id="changePasswordConfirmNewValue">
                      <LockIcon styles="w-6 h-6 mx-3 text-slate-400" />
                    </Input>
                  </div>

                  <AuthenticationButton name="Save Changes" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
