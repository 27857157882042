import { useRef, useEffect, useContext, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import context from "../../store/context";

import TendersLayout from "./TendersLayout";
import DateConverter from "../../components/DateConverter";
import TimeDifference from "../../components/TimeDifference";
import SaveUnsave from "../../components/Buttons/SaveUnsave";
import TenderDetailShimmer from "./TenderDetailShimmer";
import DetailHTML from "./DetailHTML";
import TenderContentPlaceholder from "../../components/cards/TenderContentPlaceholder";
import CompanyInfo from "../../components/cards/CompanyInfo";
import CompanyInfoShimmer from "../../components/cards/CompanyInfoShimmer";

// icons
import BackIcon from "../../components/icons/Back";
import TagIcon from "../../components/icons/Tag";

const TenderDetail = () => {
  const { setShowTenderDetail, prevRoute, setShouldLoadTenders } = useContext(
    context
  );
  const [isFetching, setIsFetching] = useState(false);
  const [response, setResponse] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  const userToken = localStorage.getItem("token");

  useEffect(() => {
    var headers = new Headers();
    headers.append("Authorization", `Bearer ${userToken}`);

    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    setIsFetching(true);

    fetch(
      `https://backend.ichereta.com/api/tender/detail/${params.tenderId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setResponse(result.data);
        setIsFetching(false);
        // console.log(result.data.content);
        console.log(result);
      })
      .catch((error) => {
        setIsFetching(false);
        console.log("error", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const currentTime = new Date();
  const isBackPressedRef = useRef(false);

  const setIsBackPressed = (value) => {
    isBackPressedRef.current = value;
  };

  useEffect(() => {
    setShouldLoadTenders(false);

    return () => {
      if (!isBackPressedRef.current) {
        setShouldLoadTenders(true);
      }
    };
  }, [setShouldLoadTenders]);

  const handleGoBack = () => {
    setIsBackPressed(true);
    setShowTenderDetail(true);
    navigate(prevRoute);
  };

  const notAUser = "Login or Subscribe";
  const notAnActiveUser = "Subscribe";

  console.log(response.company, "detail response");

  return (
    <TendersLayout redirect={true}>
      <div className="w-full h-full overflow-y-auto flex flex-col items-start p-2 sm:p-5 space-y-8 text-[#3d5984]">
        {isFetching ? (
          <TenderDetailShimmer />
        ) : (
          <div className="flex flex-col items-start space-y-2 w-full">
            <div className="flex w-full justify-between">
              <button
                onClick={handleGoBack}
                className="bg-white pl-2 pr-5 rounded hover:bg-blue-50/50 flex items-center space-x-2 shadow text-xs font-bold"
              >
                <BackIcon styles="w-5 h-5" /> <span>Back</span>
              </button>
              <div className="flex items-center space-x-2 ">
                <SaveUnsave isSavedDB={response.is_saved} id={response.id} />
              </div>
            </div>
            <div className="bg-white w-full rounded-md p-3 sm:p-4 md:p-5 shadow">
              <div className="border-b border-b-gray-200/80 pb-2 text-sm sm:text-base font-semibold text-start w-full">
                <h1>
                  #{response.id} {response.title}
                </h1>
              </div>
              <div className="flex flex-col space-y-2 text-xs sm:text-sm items-start pt-2 w-full overflow-hidden">
                <div className="flex items-center space-x-2">
                  <h1 className="font-bold">Bid Closing Date: </h1>
                  {response.bid_close_date ? (
                    <DateConverter dateString={response.bid_close_date} />
                  ) : userToken ? (
                    <span>{notAnActiveUser}</span>
                  ) : (
                    <span>{notAUser}</span>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  <h1 className="font-bold">Bid Opening Date: </h1>
                  {response.bid_open_date ? (
                    <DateConverter dateString={response.bid_open_date} />
                  ) : userToken ? (
                    <span>{notAnActiveUser}</span>
                  ) : (
                    <span>{notAUser}</span>
                  )}
                </div>

                <div className="flex items-center space-x-2">
                  <span className="font-bold">Published On: </span>
                  <span> Reporter</span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-bold">Posted: </span>
                  <span>
                    <TimeDifference
                      currentTime={currentTime}
                      dateString={response.created_at}
                    />
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-bold">Bid Document Price: </span>
                  <span>{response.bid_doc_price} Birr</span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-bold">Bid Bond Price: </span>
                  <span> --</span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-bold">Bidding: </span>
                  <span> --</span>
                </div>

                <div className="py-5 border-y border-slate-200 flex break-words w-full overflow-x-auto text-sm">
                  {response.content ? (
                    <DetailHTML htmlString={response.content} />
                  ) : (
                    <TenderContentPlaceholder />
                  )}
                </div>

                <div className="flex flex-wrap items-center pt-2">
                  {response.tender_categories &&
                    response.tender_categories.map((category, index) => {
                      return (
                        <div
                          key={`${category.category_id}-${index}`}
                          className="flex items-center space-x-2 border border-[#3d5984]/20 text-[#3d5984]/80 px-3 py-1 rounded mr-3 mb-2.5"
                        >
                          <TagIcon styles="w-4 h-4" />
                          <span>{category.category_id}name</span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}

        {isFetching ? (
          <CompanyInfoShimmer />
        ) : (
          response.company && (
            <CompanyInfo
              src={response.company.logo}
              name={response.company.name}
              id={response.company.id}
            />
          )
        )}
      </div>
    </TendersLayout>
  );
};

export default TenderDetail;
