import { useEffect, useContext } from "react";
import context from "../../store/context";

// layout
import TendersList from "./TendersList";
import NoSavedTenders from "../../components/cards/NoSavedTenders";

const SavedTenders = () => {
  const {
    setApiPath,
    setSearchParams,
    getCategories,
    getLanguagesAndRegions,
  } = useContext(context);

  useEffect(() => {
    getCategories("saved");
    getLanguagesAndRegions("saved");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setApiPath("client/saved-tender");
    setSearchParams({
      title: "",
      categoryId: "",
      regionId: "",
      languageId: "",
      startDate: "",
      endDate: "",
      status: "",
      page: "",
      companyId: "",
    });
  }, [setApiPath, setSearchParams]);

  return (
    <TendersList
      noTendersPlaceholder={<NoSavedTenders />}
      categorySlider={false}
      filter={"saved"}
    />
  );
};

export default SavedTenders;
