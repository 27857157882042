import PreferanceForm from "../../components/section/PreferanceForm";

const SettingsPage = () => {
  return (
    <div className="w-[90%] max-w-[1500px] ">
      <h1>Settings</h1>
      <PreferanceForm />
    </div>
  );
};

export default SettingsPage;
