import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";

// context
import context from "../store/context";

// components
import NavigationBar from "../components/UI/NavigationBar";
import BottomNav from "../components/UI/BottomNav";
import Footer from "../components/UI/Footer";
import SearchSidebarPhone from "../components/section/SearchSidebarPhone";

const Rootlayout = () => {
  const location = useLocation();
  const hideNavAndFooter =
    location.pathname.startsWith("/signup") ||
    location.pathname.startsWith("/login") ||
    location.pathname.startsWith("/forgot-password");

  const hideFooter =
    location.pathname.startsWith("/tenders") ||
    location.pathname.startsWith("/myfeed") ||
    location.pathname.startsWith("/saved") ||
    location.pathname.startsWith("/myaccount") ||
    location.pathname.startsWith("/billing") ||
    location.pathname.startsWith("/settings") ||
    location.pathname.startsWith("/company");

  const token = localStorage.getItem("token");

  const { showSearchDarwer, showDrawerHandler } = useContext(context);

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col items-center  min-h-screen w-full">
        {showSearchDarwer && <SearchSidebarPhone onClick={showDrawerHandler} />}
        {!hideNavAndFooter && <NavigationBar />}
        <main
          className={`flex flex-col flex-grow items-center w-full ${
            !hideNavAndFooter &&
            " mt-[61px] sm:mt-[73px] md:mt-[69px] lg:mt-[79px]"
          }`}
        >
          <Outlet />
        </main>
      </div>

      {!hideNavAndFooter && !hideFooter && <Footer />}
      {token && !showSearchDarwer && <BottomNav />}
    </div>
  );
};

export default Rootlayout;
