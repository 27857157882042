import { Link } from "react-router-dom";
const CompanyInfo = ({ src, name, id }) => {
  return (
    <div className="w-full space-y-3">
      <h1 className="">COMPANY INFORMATION.</h1>
      <div className="w-full space-y-4 sm:space-y-0 p-5 bg-white rounded-md shadow flex flex-wrap flex-col sm:flex-row justify-center sm:justify-between items-center">
        <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row items-center  sm:space-x-5">
          <div className="w-[100px] h-[100px] rounded-full overflow-hidden shadow-md">
            <img src={src} alt="company logo" />
          </div>
          <div className="sm:space-y-2">
            <h1>{name}</h1>
            <h1>{name}</h1>
          </div>
        </div>
        <div className="h-[60px] flex items-center justify-center min-w-max w-full sm:w-auto">
          <Link
            to={`/company/${id}`}
            className="bg-[#664bc7]/20 text-[#664bc7] w-full max-w-[300px] sm:w-[200px] py-2 rounded text-center"
          >
            See Tenders
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
