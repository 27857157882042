import { Link } from "react-router-dom";

// components
import Input from "../Input";
import AuthenticationButton from "../Buttons/AuthenticationButton";

// hooks
import useInput from "../../hooks/use-Input";
import useNewPassword from "../../hooks/use-newPassword";

// icons
import PhoneIcon from "../icons/Phone";

const ForgotPasswordSection = (props) => {
  const phoneValidator = (enteredValue) => {
    const phoneRegex = /^(09|\+251)\d{8}$|^(\+251)\d{9}$/;
    return phoneRegex.test(enteredValue);
  };

  const {
    enteredValue: phoneValue,
    hasError: phoneHasError,
    valueChangeHandler: phoneValueChangeHandler,
    inputBlurHandler: phoneInputBlurHandler,
    isTouched: phoneIsTouched,
    isValid: phoneIsValid,
    reset: phoneReset,
  } = useInput(phoneValidator);

  const transformedPhoneValue = phoneValue.startsWith("+251")
    ? "0" + phoneValue.substring(4)
    : phoneValue;

  var raw = JSON.stringify({
    phone: transformedPhoneValue,
  });

  const reqParameters = {
    route: "forgot-password",
    data: raw,
    case: phoneIsValid,
    case2: phoneValue.trim().length === 0,
    onClick: props.onClick,
    onSubmit: props.onSubmit,
    errorMessage: "Can not be empty",
  };

  const { isSending, hasError, errorMessage, sendRequest } = useNewPassword(
    reqParameters
  );

  return (
    <div className="w-full flex flex-col space-y-3 md:space-y-5">
      <div className="flex flex-col items-center text-[#664bc7] space-y-1 sm:space-y-2.5 mb-12">
        <span className="font-semibold text-3xl md:text-[35px]">
          Lost Password Reset
        </span>
        <div className="font-normal text-sm sm:w-2/3 text-center text-gray-700">
          Forgotten your password? Enter your phone number below to begin the
          reset process
        </div>
      </div>
      <div className="flex flex-col space-y-3">
        <Input
          onChange={phoneValueChangeHandler}
          onBlur={phoneInputBlurHandler}
          hasError={phoneHasError}
          isValid={phoneIsValid}
          isTouched={phoneIsTouched}
          enteredValue={phoneValue}
          reset={phoneReset}
          error={hasError}
          errorMessage={errorMessage}
          type="tel"
          id="resetPassword"
          lable="Phone Number"
        >
          <PhoneIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="space-y-3 sm:space-y-4 md:space-y-5">
        <Link to="/login">
          <button className="text-[#664bc7] text-sm sm:text-base font-medium">
            Back to Login
          </button>
        </Link>

        <AuthenticationButton
          process={isSending}
          onClick={sendRequest}
          name="Reset Password"
        />
      </div>
    </div>
  );
};

export default ForgotPasswordSection;
