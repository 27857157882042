import PriceCard from "../cards/Price";

const PricingSection = () => {
  return (
    <div className="w-full flex flex-col  items-center">
      <div className="flex flex-col items-center my-12 sm:my-16 md:my-20 lg:my-24 space-y-1 sm:space-y-3 text-gray-800">
        <span className="text-[5vw] sm:text-3xl md:text-4xl font-medium">
          Simple & Straightforward
          <span className="text-[#664bc7]"> Pricing. </span>
        </span>
        <span className="text-[3vw] sm:text-sm">
          ALL PRICES INCLUDE 15% VAT.
        </span>
      </div>

      <div className="w-[90%] max-w-[1500px] flex flex-col space-y-8 md:hidden justify-between items-center">
        <PriceCard
          id="1"
          time="3 Months"
          price="980"
          phone={true}
          showBtn={true}
        />
        <PriceCard
          id="3"
          time="1 Year"
          price="2,360"
          phone={true}
          showBtn={true}
          alert={true}
        />
        <PriceCard
          id="2"
          time="6 Months"
          price="1,780"
          phone={true}
          showBtn={true}
        />
      </div>
      <div className="w-[90%] max-w-[1500px] hidden md:flex flex-col lg:flex-row space-y-12 lg:space-y-0 lg:space-x-10 xl:space-x-24  justify-center items-center xl:bg-[#f9f8fd]/80 xl:py-24 rounded-md">
        <PriceCard
          id="1"
          time="3 Months"
          price="980"
          phone={false}
          showBtn={false}
        />
        <PriceCard
          id="3"
          time="1 Year"
          price="2,360"
          phone={false}
          showBtn={false}
          alert={true}
        />
        <PriceCard
          id="2"
          time="6 Months"
          price="1,780"
          phone={false}
          showBtn={false}
        />
      </div>
    </div>
  );
};

export default PricingSection;
