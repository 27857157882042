import { Link } from "react-router-dom";

const FooterBannerSection = () => {
  return (
    <div className="w-[90%] max-w-[1500px] z-10  md:h-[250px] h-full rounded-md relative flex flex-col md:flex-row items-center -mb-24  my-12 overflow-hidden shodow">
      <div className="w-full h-full md:w-1/2  bg-slate-100 md:px-8 lg:px-12 xl:px-24 flex items-center justify-center md:justify-start py-4 sm:py-6 md:py-0">
        <span className="text-2xl sm:text-3xl md:text-3xl lg:text-4xl font-bold text-[#29204e] text-center md:text-left ">
          Grow Your Business <br />
          With
          <span className="text-[#6449c3] ml-2">iChereta.</span>
        </span>
      </div>

      <div className="flex flex-col items-center justify-center space-y-3 bg-[#f9f8fd] w-full md:w-1/2 h-full py-4 sm:py-6 md:py-0">
        <Link to="/signup">
          <button className="bg-[#ebba5c] hover:bg-yellow-600/70 transition-colors duration-200 py-3 w-52 rounded font-semibold text-white my-4">
            Sign Up Now
          </button>
        </Link>
        <div className="flex flex-col items-center text-[#29204e] font-bold">
          <span>Got Questions?</span>
          <span>Call Us 0900000000</span>
        </div>
      </div>
    </div>
  );
};

export default FooterBannerSection;
