import { useState } from "react";

import MessageIcon from "../icons/Message";

const RegistrationConfirmation = (props) => {
  const { firstName, paymentInfo } = props.stepTwoData;
  const { amount, banks, invoice_number } = paymentInfo;

  const [accountNumber, setAccountNumber] = useState(banks[0].account_number);
  const [accountName, setAccountName] = useState(banks[0].account_name);

  const bankSelector = (event) => {
    const selectedOption = banks.find(
      (bank) => bank.bank_name === event.target.value
    );
    if (selectedOption) {
      const accountNumber = selectedOption.account_number;
      const accountName = selectedOption.account_name;

      setAccountName(accountName);
      setAccountNumber(accountNumber);
    }
  };

  return (
    <div className={`text-3xl text-center rounded flex flex-col`}>
      <div className="flex flex-col  text-[#664bc7] space-y-1.5 sm:space-y-3 pb-8">
        <span className="text-xl sm:text-3xl font-bold">
          ሰላም 👋 , {firstName}!
        </span>
        <h1 className=" text-sm xl:text-xl">
          You've
          <span className="text-green-500 font-semibold mx-2">
            successfully
          </span>
          created your iChereta Account.
        </h1>
      </div>

      <div className="w-full border border-gray-300 rounded-md relative text-sm p-5">
        <div className="absolute left-6 -top-3.5 bg-white px-2">
          Payment Methods
        </div>
        <div className="my-5 flex space-x-2 sm:space-x-5 text-xs sm:text-sm ">
          <button className="tablinks bg-[#664bc7] shadow text-white px-3 sm:px-8 py-1.5 min-w-max">
            Bank Transfer
          </button>
          <button className="tablinks bg-[#664bc7] shadow text-white px-3 sm:px-8 py-1.5 min-w-max">
            Telebirr
          </button>
          <button className="tablinks bg-[#664bc7] shadow text-white px-3 sm:px-8 py-1.5 min-w-max">
            CBE Birr
          </button>
        </div>

        <div className="tabcontent">
          <select
            className="w-full flex justify-between outline-none py-2 px-2 bg-slate-50 border border-slate-100 rounded-md"
            name=""
            onChange={bankSelector}
          >
            {banks.map((bank) => (
              <option key={bank.id} value={bank.bank_name}>
                {bank.bank_name}
              </option>
            ))}
          </select>
          <div className="flex flex-col my-4 space-y-2 w-full items-start">
            <span>
              <span className="font-bold ">Name: </span> {accountName}
            </span>
            <span>
              <span className="font-bold ">Account Number: </span>
              {accountNumber}
            </span>
            <span>
              <span className="font-bold ">Amount: </span>
              {amount} Birr
            </span>
            <span>
              <span className="font-bold ">Invoice No: </span>#{invoice_number}
            </span>
          </div>
          <button className="py-2 pl-3 pr-6 rounded bg-red-600 text-white flex items-center">
            <MessageIcon styles="w-6 h-6 text-white mr-2" />
            <span>Text Me Account Number</span>
          </button>
        </div>

        <div id="Telebirr" className="tabcontent hidden">
          <p>Telebirr content</p>
        </div>

        <div id="CBEBirr" className="tabcontent hidden">
          <p>CBE content</p>
        </div>
      </div>
    </div>
  );
};

export default RegistrationConfirmation;
