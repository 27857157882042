import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import context from "../store/context";

// import TendersLayout from "./TendersLayout";

import TendersList from "./Tender/TendersList";
import CompanyProfileCard from "../components/cards/CompanyProfile";

const CompanyDetail = () => {
  const { setApiPath, setSearchParams } = useContext(context);

  const params = useParams();

  useEffect(() => {
    setApiPath("tender/search");

    setSearchParams({
      title: "",
      categoryId: "",
      regionId: "",
      languageId: "",
      startDate: "",
      endDate: "",
      status: "",
      page: "",
      companyId: params.companyId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setApiPath, setSearchParams]);
  return (
    <TendersList profileCard={<CompanyProfileCard />} categorySlider={false} />
  );
};

export default CompanyDetail;
